<template>
    <div class="container-fluid finance-cont-fluid">
      <div class="container finance-cont">
        <img id="title" src="../assets/warranty.jpg">
      </div>
  
      <div class="buy-your-car container-fluid">
        <div class="container">
          <div class="row main-title g-4">
            <!-- First column (1/3) -->
            <div class="col-12 col-sm-4">
              <div class="buy-your-car-column">
                <!-- Your content for the first column goes here -->
                <img id="sell-car-img" src="../assets/finance.jpeg" alt="">
              </div>
            </div>
    
            <!-- Second column (2/3) -->
            <div class="col-12 col-sm-8">
              <div class="buy-your-car-column">
                <!-- Your content for the second column goes here -->
                <h1 class="display-4 mb-3">We have you covered</h1>
                <p class="lead mb-4">Get in touch to hear your best options</p>
                
    
                <div class="title-info">
                  <p>
                    At Barden Motor group we offer warranty protection with Warranty Assist on all of our used cars so you can drive away happy with complete peace of mind.

                    The warranty protects your car by covering the costs of repairs and maintenance to help keep you on the road.
                    
                    We are also able to offer extendable options on your new purchase for 6, 12 or 24 month extensions.
                  </p>                </div>
              </div>
            </div>
          </div>
    
          <div class="other mt-5">
            <button class="whatsappButton btn">
              <i class="fab fa-whatsapp me-2"></i> Contact via WhatsApp
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      // Replace '123456789' with the desired phone number
      const phoneNumber = '+447464873211';
  
      // Replace 'Hello%20World' with the pre-determined message
      const message = `Hello! I would like to discuss what options are available regarding warranty. Looking forward to hearing from you!`;
  
      const whatsappButton = document.querySelector('.whatsappButton');
  
      whatsappButton.addEventListener('click', function() {
        // Construct the WhatsApp link
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  
        // Open the link in a new tab or window
        window.open(whatsappLink, '_blank');
      });
    },
  };
  </script>
  
  <style scoped>

  
  #title {
    text-align: center;
    margin: 2rem auto;
    width: 375px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

.finance-cont-fluid {
    background-color: #f8f9fa;
    min-height: 100vh;
}

.finance-cont{
  text-align: center;

}



#sell-car-img {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

#sell-car-img:hover {
    transform: scale(1.02);
}

.buy-your-car {
    background-color: #000717;
    min-height: 100vh;
    color: #ffffff;
    padding-bottom: 100px;
}

.buy-your-car-column > h1 {
    padding: 10px;
    padding-left: 0px;
}

.title-info {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    min-height: 18rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;
}

.title-info p {
    color: #495057;
    line-height: 1.8;
    margin-bottom: 0;
}

.main-title {
    border-bottom: none;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.whatsappButton {
    background-color: #25D366;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 50px;
    transition: transform 0.2s, box-shadow 0.2s;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.whatsappButton:hover {
    background-color: #128C7E;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(37, 211, 102, 0.2);
}

/* Responsive adjustments */
@media (max-width: 767px) {
    #title {
        width: 90%;
        max-width: 375px;
    }
    
    .whatsappButton {
        width: 100%;
        max-width: none;
    }
}
</style>
