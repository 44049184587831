<template>
<div class="container-fluid indiv-cont">
    <div class="container">
        <div class="content-wrapper">
            <div v-for="(entry, index) in entries" :key="index">
                <!-- Image Slider -->
                <div class="indivCar-Info" v-if="entry.id == carId">
                    <h2 id="title">{{ entry.name }}</h2>

                </div>
                <div v-if="entry.id == carId" class="content-section">
                    <div class="image-gallery">
                        <div class="main-image">
                            <img 
                                :src="currentImage || entry.mainImage.fields.file.url" 
                                alt="Main Car Image" 
                                class="featured-image"
                            >
                        </div>
                        <div class="thumbnail-grid">
                            <img 
                                :src="entry.mainImage.fields.file.url" 
                                alt="Car Image" 
                                class="thumbnail"
                                @click="currentImage = entry.mainImage.fields.file.url"
                                :class="{ active: currentImage === entry.mainImage.fields.file.url }"
                            >
                            <template v-for="(image, index) in entry.otherImages" :key="index">
                                <img 
                                    v-if="image?.fields?.file?.url"
                                    :src="image.fields.file.url" 
                                    alt="Other Image" 
                                    class="thumbnail"
                                    @click="currentImage = image.fields.file.url"
                                    :class="{ active: currentImage === image.fields.file.url }"
                                >
                            </template>
                        </div>
                    </div>
                    <div class="description-container">
                        <p id="description">{{ entry.description }}</p>
                    </div>
                </div>
                <!-- Other Car Information and WhatsApp Button -->
                <div v-if="entry.id == carId" class="indivCar-final">
                    <div class="car-properties">
                        <p class="car-property">Odometer: <span class="car-property-value">{{ entry.odometer }}</span></p>
                        <p class="car-property">Fuel Type: <span class="car-property-value">{{ entry.fuelType }}</span></p>
                        <p class="car-property">Transmission: <span class="car-property-value">{{ entry.transmission }}</span></p>
                        <p class="car-property">Doors: <span class="car-property-value">{{ entry.doors }}</span></p>
                        <p class="car-property">Engine Size: <span class="car-property-value">{{ entry.engineSize }}</span></p>
                        <p class="car-property">Body Type: <span class="car-property-value">{{ entry.body }}</span></p>
                        <p class="car-property">Year: <span class="car-property-value">{{ entry.year }}</span></p>
                        <p class="car-property">Colour: <span class="car-property-value">{{ entry.colour }}</span></p>
                    </div>
                </div>
                <button class="whatsapp-button" v-if="entry.id == carId" @click="openWhatsApp(entry.name)">
                  <i class="fab fa-whatsapp"></i>
                  Contact via WhatsApp</button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    useAllContentfulEntries
} from "@/composables/getEntries";
import {
    useRoute
} from "vue-router";
import {
    ref,
    watch
} from "vue";
import {
    Splide,
    SplideSlide
} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
export default {
    setup() {
        const {
            entries,
            loading,
            error
        } = useAllContentfulEntries();
        const route = useRoute();
        const carId = ref(route.params.ID);
        const currentImage = ref(null);

        console.log("Entries: ", entries);

        watch(() => entries.value, (newEntries) => {
            if (newEntries) {
                const currentEntry = newEntries.find(entry => entry.id === carId.value);
                if (currentEntry) {
                    console.log("Other Images:", currentEntry.otherImages);
                }
            }
        }, { immediate: true });

        return {
            entries,
            carId,
            currentImage
        };
    },
    components: {
        Splide,
        SplideSlide
    },
    methods: {
        openWhatsApp(name) {
            const phoneNumber = '+447585213117';
            const message = encodeURIComponent(`Hi, I would like to know more about ${name} and whether it is available or not.`);
            const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
            window.open(whatsappLink, '_blank');
        },
    }
};
</script>

<style scoped>
.indiv-cont {
    background-color: #000717;
    min-height: 100vh;
    padding: 0;
    margin: 0px;
}

.img-slider {
    background-color: transparent;
    border-radius: 10px;
    height: 600px;
    width: 100%;
    overflow: hidden;
}

.slider-image {
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 0;
}

#title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    border: none;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 60px;
    background: #25D366;
    transition: width 0.3s ease;
}

#title:hover::after {
    width: 100%;
}

@media screen and (max-width: 767px) {
    #title {
        font-size: 2rem;
    }
}

#description {
    color: #ccc;
    background-color: #1a1a2e;
    padding: 20px;
    border-radius: 15px;
    margin: 30px 0;
    text-align: justify;
    line-height: 1.6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.indivCar-Info {
    background-color: transparent;
    display: inline-block;
    padding: 20px;
    border-radius: 10px;
    padding-top: 20px;
    margin-bottom: 20px;
    border: none;
    width: 100%;
}

.car-properties {
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    color: #eee;
    padding: 20px 0;
}

.car-property {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    background-color: #1a1a2e;
    border: none;
    margin: 0;
    padding: 8px 16px;
    border-radius: 25px;
    line-height: 1.5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.car-property:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.car-property-value {
    font-weight: 600;
    margin-left: 6px;
    color: #25D366;
}

@media screen and (max-width: 767px) {
    .img-slider {
        height: 400px;
    }

    .slider-image {
        height: 400px;
    }
}

.whatsapp-button {
  display: inline-block;
  background-color: #25D366;
  color: #fff;
  text-decoration: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #128C7E;
}

.whatsapp-button i {
  margin-right: 5px;
}

.content-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 15px;
}

@media screen and (max-width: 767px) {
    .content-wrapper {
        max-width: 100%;
    }
}

:deep(.splide__track) {
    background-color: transparent;
}

:deep(.splide__slide) {
    background-color: transparent;
}

.content-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}

.image-gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.main-image {
    width: 100%;
    height: 600px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #000717;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featured-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    width: 100%;
}

.thumbnail {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.thumbnail:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.thumbnail.active {
    border: 2px solid #25D366;
}

@media screen and (max-width: 767px) {
    .content-section {
        grid-template-columns: 1fr;
    }

    .main-image {
        height: 300px;
    }

    .thumbnail-grid {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }

    .thumbnail {
        height: 60px;
    }

    .featured-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>
