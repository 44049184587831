<template>
  <div class="container-fluid used-cars-contfluid">
    <div class="container used-cars-cont">
      
      <div class="bar">
          <h5>Current stock</h5>
      </div>

      <br/>
      <br/>

      <div v-for="entry in entries" :key="entry.id" class="car-parent-cont">
        <!-- Display your entry data here -->
        <Car :Name="entry.name" :Desc="entry.description" :Price="entry.price" :mainImageURL="entry.mainImage.fields.file.url" :otherImages="entry.otherImages"  :ID="entry.id" :entry="entry"/>
        <!-- Add more fields based on your Contentful content structure -->
      </div>

    </div>  
  </div>
</template>

<script>
import { useAllContentfulEntries } from '@/composables/getEntries';
import Car from '../components/Car.vue'

export default {
  setup() {
    const { entries, loading, error } = useAllContentfulEntries();

    return { entries, loading, error };
  },
  components:{
    Car
  }
};
</script>

<style scoped>
.used-cars-contfluid{
  min-height: 100vh;

  background-color: #000717;
  padding-bottom: 100px;
}
.bar {
  display: inline-block;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  margin-left: 0px;
  padding: 10px;
  background-color: #00B4CC;
}

@media (max-width: 450px) {
  .bar{
    width: 100%;
  }
}

.car-parent-cont {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  flex-direction: column;
}

:deep(.car-component) {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #000717;
  border: 1px solid #1a1a1a;
  overflow: hidden;
}

:deep(.image-container) {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  background-color: #000717;
}

@media (max-width: 768px) {
  :deep(.image-container) {
    height: 200px;
  }
}

:deep(.car-image) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Add custom container width */
.used-cars-cont {
  max-width: 1000px; /* You can adjust this value */
  margin: 0 auto;
  padding: 0 15px; /* Add some padding for mobile */
}

/* Make sure the container is responsive */
@media (max-width: 1100px) {
  .used-cars-cont {
    max-width: 90%;
  }
}
</style>
