<template>
  <div class="container-fluid finance-cont-fluid">
    <div class="container finance-cont">
      <h1 id="title">Get in contact for a review of your vehicle</h1>
    </div>
  <div class="buy-your-car container-fluid">
    <div class="container">
      <div class="row main-title align-items-center">
        <!-- First column -->
        <div class="col-12 col-sm-4">
          <div class="buy-your-car-column">
            <img id="sell-car-img" src="../assets/money.jpeg" alt="" class="rounded shadow-lg">
          </div>
        </div>

        <!-- Second column -->
        <div class="col-12 col-sm-8">
          <div class="buy-your-car-column">
            <h1 class="display-4 mb-3">Want to sell your car?</h1>
            <p class="lead mb-4">Let us help you get the best value for your vehicle</p>

            <div class="title-info">
              <p class="mb-0">
                At Barden Motor Group Ltd, we simplify the process of selling your used car. Whether you're upgrading or parting ways, our platform ensures a transparent and hassle-free experience. Our team provides fair valuations, considering your vehicle's unique features. With a user-friendly interface and a commitment to integrity, we offer a convenient and efficient solution for those looking to sell their cars confidently. Trust Barden Motor Group Ltd for a seamless transition in selling your used car.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="other mt-5 text-center">
        <button class="whatsappButton btn">
          <i class="fab fa-whatsapp me-2"></i>Contact via WhatsApp
        </button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
mounted() {
  // Replace '123456789' with the desired phone number
  const phoneNumber = '+447464873211';

  // Replace 'Hello World' with the pre-determined message
  const message = 'Hello! I would like to sell my car and discuss this further. Looking forward to hearing from you!';

  const encodedMessage = encodeURIComponent(message);

  const whatsappButton = document.querySelector('.whatsappButton');

  whatsappButton.addEventListener('click', function() {
    // Construct the WhatsApp link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open the link in a new tab or window
    window.open(whatsappLink, '_blank');
  });
}
}
</script>

<style scoped>
#sell-car-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  transition: transform 0.3s;
}

#sell-car-img:hover {
  transform: scale(1.02);
}

.buy-your-car {
  background: linear-gradient(135deg, #000717 0%, #1a1a2e 100%);
  min-height: 100vh;
  color: #ffffff;
  padding: 4rem 0;
}
.finance-cont-fluid {
  background-color: #000717;
}

#title {
  text-align: center;
  color: white;
  padding: 1.5rem;
  width: 100%;
  background-color: #6b0080;
  margin-bottom: 2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.buy-your-car-column > h1 {
  padding: 10px;
  padding-left: 0px;
}

.title-info {
  background-color: rgba(41, 41, 43, 0.8);
  padding: 2rem;
  border-radius: 12px;
  min-height: 18rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.title-info p {
  color: #fff;
  line-height: 1.8;
  font-size: 1.1rem;
}

.main-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.whatsappButton {
  background-color: #25D366; /* WhatsApp brand color */
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 12px rgba(37, 211, 102, 0.3);
}

.whatsappButton:hover {
  background-color: #128C7E;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(37, 211, 102, 0.4);
}

@media (max-width: 767px) {
  .title-info {
    padding: 1.5rem;
  }
  
  .whatsappButton {
    width: 100%;
    max-width: none;
  }
}
</style>
